import Info from "./info"
import OverallExperience from "./overall_experience"
import Status from "./status"
import ExperienceWrapper from "./experience_wrapper";
import LabDataMetrics from "./lab_data_metrics";
import OpportunityWrapper from "./opportunity_wrapper";
import UnderstandPageWeight from "./understand_page_weight";
import ContactExpert from "../contact/expert";
import dateFormat, { masks } from "dateformat";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import TalkToExpert from "../contact/talkToExpert";
import UrlDateCopy from "../common/urlDateCopy";
import Footer from '../common/footer';
import ContactForm from "../common/contactForm";
import CtaOne from "../contact/cta_one";
import CtaTwo from "../contact/cta_two";

function Audit(props){
    let localDate = new Date(props.date)
    localDate = dateFormat(localDate, "mmmm dS yyyy h:MM:ss TT")
    let auditDetails = props.auditDetails

    console.log(props.isShowRecommendations);
    let blur = {filter: "blur(5px)"}
    if(!props.isShowRecommendations || props.isBulk)
      blur = {filter: "blur(0px)"} 

    return(
        <>
          <div id={props.device}>
            <UrlDateCopy redirectInfo={props.redirectInfo} isReportFullyLoaded={props.isReportFullyLoaded} completedAudit={props.completedAudit} selectedAudit={props.selectedAudit} auditDetails={auditDetails} setCopied={props.setCopied} copied={props.copied} date={props.date} url={props.url} />
            <div className="mt-3 status-top">
              <Status url={props.url} device={props.device} date={localDate} cwvStatus={props.data.cwv.status}/>
              <Info device={props.device} setCopied={props.setCopied} copied={props.copied} audits={props['data']['audits']} audits-ylb={props['data-ylb']} config={props.config} scores={props.data.scores}/>              
            </div>
            {/* <TalkToExpert/> */}
            {(props.isShowRecommendations && !props.isBulk) &&
            
            <ContactForm url={props.url} phone={props.phone} setPhone={props.setPhone} buttonText={props.buttonText} setButtonText={props.setButtonText} contactExpert={props.contactExpert} setContactExpert={props.setContactExpert} contactFormSuccessMessage={props.contactFormSuccessMessage} setContactFormSuccessMessage={props.setContactFormSuccessMessage} name={props.name} email={props.email} setName={props.setName} setEmail={props.setEmail} showModal={props.showModal} setShowModal={props.setShowModal} setIsShowRecommendations={props.setIsShowRecommendations}/>

            }
            <div style={blur}>
              <OverallExperience experience={props.data.experience}/>
              <ExperienceWrapper data={props.data.cwv} />
              { props?.redirectInfo?.cta_calendar != null &&
                <CtaOne redirectInfo={props.redirectInfo}/>
              }
              <LabDataMetrics device={props.device} data={props.data.audits}/>
              { props?.['data-ylb']?.['totalRequests'] != undefined &&
                <UnderstandPageWeight device={props.device} data_r={props['data-ylb']['totalRequests']} data={props['data-ylb']['totalWeight']}/>
              }
              <OpportunityWrapper redirectInfo={props.redirectInfo} config={props.config} setConfig={props.setConfig} audits={props['data']['audits']} audits-ylb={props['data-ylb']} />
              { props?.redirectInfo?.cta_calendar != null &&
                <CtaTwo redirectInfo={props.redirectInfo}/>
              }
            </div>
          </div>
          {/* <Footer isShowCta={props.isShowCta} redirectInfo={props.redirectInfo} title="Looking for site speed optimization?" content="Book a free consultation with our Certified Experts" buttonText="Talk To Our Expert" expertImg="https://www.thecommerceshop.com/wp-content/uploads/2023/01/sticky-nash.png" /> */}
        </>
    )
}

export default Audit