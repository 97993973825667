import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons';

function CtaOne(props){

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const openModal = () => {
        setModalIsOpen(true);
        setLoading(true);
      };
    
      const closeModal = () => {
        setModalIsOpen(false);
        setLoading(true);
      };

    return(
        <div className="over-infos ctaone">
            <div className="cta-content">
                <h4>Get a 100% Free Expert Consultation to Improve Your Website Speed & Performance</h4>
                <p>Schedule a Meeting In the Calendar</p>
            </div> 
            <div className="ctaone-btn">
                <button className="btn-area-in" onClick={openModal}>Schedule Meeting</button>  
            </div>            
                
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Schedule Meeting Modal"
                style={{
                    overlay: {
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    //   backgroundColor: 'rgba(255, 255, 255, 0.75)'
                      backgroundColor: 'rgba(0, 0, 0, 0.75)'
                    },
                    content: {
                      position: 'absolute',
                      top: '200px',
                      left: '200px',
                      right: '200px',
                      bottom: '200px',
                      border: '1px solid #ccc',
                      background: '#fff',
                      overflow: 'hidden',
                      WebkitOverflowScrolling: 'touch',
                      borderRadius: '4px',
                      outline: 'none',
                      padding: '20px'
                    }
                  }}
            >
                <FontAwesomeIcon icon={faClose} className="close-modal" onClick={closeModal} fontSize={"2rem"} style={{float:"right", position:"absolute", right:"10px", top:"10px"}} />    

                {loading && (
                <div className="ctaone-loader-container">
                    <p className="ctaone-loader"><img height="50" src='/img/loader-pulse.gif'/> Please wait, we are checking available slots...</p>
                    
                </div>
                )}    

                <iframe
                     src={props.redirectInfo.cta_calendar}
                     style={{
                        border: "none",
                        width: "100%",
                        height: "100%",
                        display: loading ? "none" : "block"
                      }}
                      frameborder="0" 
                      onLoad={() => setLoading(false)}></iframe>

            </Modal>  
		</div>
    )
}

export default CtaOne