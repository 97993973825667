import Modal from 'react-modal';
import axios from 'axios';
import "./css/contactForm.css";
import { useLocation } from 'react-router-dom';
import parse from "url-parse";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import PhoneInput from 'react-phone-number-input'
import { toast, ToastContainer } from 'react-toastify';
Modal.setAppElement('#modal-root'); // set the root element for the modal


const ContactForm = (props) => {

  let name = props.name;
  let email = props.email;
  let phone = props.phone;
  let setName = props.setName;
  let setEmail = props.setEmail;
  let setPhone = props.setPhone;
  let showModal = props.showModal;
  let setShowModal = props.setShowModal;
  let contactExpert = props.contactExpert;
  let setContactExpert = props.setContactExpert;
  let contactFormSuccessMessage = props.contactFormSuccessMessage;
  let setContactFormSuccessMessage = props.setContactFormSuccessMessage;
  let audit_url = props.url;
//   let url = 'https://vir16qej5k.execute-api.us-east-1.amazonaws.com/production/lead-update-contact';
  let url = 'https://api.thecommerceshop.com/lead-update-contact';
  // Get report ID from the current URL
  const location = useLocation();
  const parsed = parse(location.search, true);
  const report_id = parsed.pathname.split("/").pop();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateEmailFormat(email) || isRestrictedDomain(email)) {
      let mainDomain = extractDomain(audit_url);
      document.getElementById('email-validation').innerText = 'Enter a valid business email. e.g. john@'+mainDomain;
      return false;
    }
    // if(!validateNameFormat(name))
    // {
    //   document.getElementById('name-validation').innerText = 'Enter alphapets only';
    //   return false;
    // }
    document.getElementById('email-validation').innerText = '';
    // document.getElementById('name-validation').innerText = '';
    
    props.setButtonText('Submitting...');
    let loader = {loader:true};
    props.setContactFormSuccessMessage({...contactFormSuccessMessage,...loader});
    if(typeof phone === 'undefined')
      phone = '';
    axios({
        method: 'post',
        url: url,
        data: {
          name: name,
          email: email,
          phone: phone,
          contact_expert: contactExpert,
          unique_report_id: report_id
        }
    }).then(function (response) {
        // Clear the form after submission
        setName('');
        setEmail('');
        setPhone('');
        setContactExpert(true);
        setShowModal(false);
        props.setIsShowRecommendations(false);
        toast.success('Thanks for sharing the information!', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      })
      .catch(function (error) {
        setContactFormSuccessMessage('Something went wrong.');
    });

  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setContactExpert((event.target.checked) ? 1 : 0);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="showmore-container">
      <div className="showmorebutton">
        <button className="contact-button" onClick={toggleModal}><b>Show Full Report</b></button>
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={toggleModal}
        contentLabel="Contact Form"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div>
            <span className="contact-title"><b>Your Comprehensive Website<br/> Performance Report is Ready</b></span>
            <p className="contact-content">Fill in the details below and access the full report along with a step-by-step action plan to boost your site performance
            </p>
        </div>
        <div>
            <form id="contact-expert" onSubmit={handleSubmit}>
            <label>
                <input className="cinput" type="text" maxlength="32" value={name} onChange={handleNameChange} pattern="[a-zA-Z][a-zA-Z ]+" title="Enter alphabets only." placeholder="Enter your name*" required/>
                <span id="name-validation"></span>
            </label>
            <label>
                <input className="cinput" type="email" id="email" value={email} onChange={handleEmailChange} placeholder="Enter your business email address*" required/>
                <span id="email-validation"></span>
            </label>            
            <label>
            <PhoneInput
              defaultCountry="US"
              className="cinput"
              placeholder="Enter phone number"
              value={phone}
              required
              onChange={setPhone}/>
            </label>
            <div className="aggreement">
            <label>
                <input className="cradio" type="checkbox" checked={contactExpert} onChange={handleCheckboxChange} />
            </label>
            <span>I’d also like to receive free website speed boost solutions from an expert.</span>
            </div>
            <div className="showmorebutton">
             <button className="contact-button" type="submit"><b>{props.buttonText}</b>
             { contactFormSuccessMessage.loader &&
                <img style={{display:"inline-block"}} src="/img/loader-pulse.gif" width="25" height="25"/>
             }
             </button>
            </div>
            <div className="msg-contact">
                { contactFormSuccessMessage.error ?
                    <p>{contactFormSuccessMessage.msg}</p> : <p></p>
                }
                { contactFormSuccessMessage.success ?
                    <p>{contactFormSuccessMessage.msg}</p> : <p></p>
                }
            </div>
            </form>
        </div>
        
      </Modal>
    </div>
  );
};


function validateEmailFormat(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function isRestrictedDomain(email) {
  const restrictedDomains = ['gmail.com', 'yahoo.com', 'hotmail.com'];
  const domain = email.split('@')[1];
  return restrictedDomains.includes(domain);
}

function validateNameFormat(name) {
  const nameRegex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
  return nameRegex.test(name) && !name.startsWith(' ');
}

function extractDomain(url) {
  // Remove protocol (e.g., 'http://', 'https://')
  let domain = url.replace(/(^\w+:|^)\/\//, '');

  // Remove path
  domain = domain.split('/')[0];

  // Extract main domain
  const domainParts = domain.split('.');
  const mainDomain = domainParts.slice(-2).join('.');

  return mainDomain;
}

export default ContactForm;