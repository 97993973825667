import React, { useState } from 'react';

function CtaTwo(props){

    return(
        <div className="over-infos ctatwo">
            <h4>Are these results making your head spin? Don't worry — we have a quick solution!</h4>
            <p>Book a meeting with our website performance experts and get actionable solutions to fix your site. It's a 100% free service!</p>               
            <iframe loading='eager' src={props.redirectInfo.cta_calendar} style={{border:"none"}} width="100%" height="1050" frameborder="0"></iframe>
		</div>
    )
}

export default CtaTwo